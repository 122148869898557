import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"

import { FaFacebook } from "react-icons/fa"
import { IoLogoYoutube, IoMdMail } from "react-icons/io"
import Logof from "./logof"

import "../assets/css/footer.css"

const Footer = props => {
  return (
    <Container fluid className="footer px-lg-5">
      <Row>
        <Col lg="2" className="text-center mx-auto">
          <Logof />
        </Col>
        <Col lg="7">
          <div className="links">
            <Row className="text-center justify-content-center">
              <ul className="single-links">
                <li>
                  <Link to="/" className="link">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/products" className="link">
                    Products
                  </Link>
                </li>
                <li>
                  <Link to="/solutions" className="link">
                    Solutions
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="link">
                    Services
                  </Link>
                </li>
                <li>
                  <a href="/hp/#" className="link">
                    HP Catalogue
                  </a>
                </li>
                <li>
                  <Link to="/contact" className="link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Row>
            <Row className="d-flex flex-column p-2">
              <div className="soc-container text-center">
                <a
                  href="https://www.facebook.com/FastlinkComputerCo"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaFacebook className="soc-icon" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCLOkwXwNUax_RTqEqa2jeGQ"
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoLogoYoutube className="soc-icon"></IoLogoYoutube>
                </a>
                <a
                  href="mailto:inquiry@fastlink.ph"
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoMdMail className="soc-icon"></IoMdMail>
                </a>
              </div>
              <Col className="text-center mx-auto mt-1">
                <p style={{ textAlign: `center` }}>
                  © Fastlink Computer Co. 2020
                </p>
                <p className="text-center" style={{ marginTop: "-1rem" }}>
                  <Link
                    to="/policies/terms-of-service"
                    style={{ color: "#143469", fontSize: "0.8rem" }}
                  >
                    Terms of Service
                  </Link>{" "}
                  |
                  <Link
                    to="/policies/privacy-policy"
                    style={{ color: "#143469", fontSize: "0.8rem" }}
                  >
                    Privacy Policy
                  </Link>{" "}
                  |
                  <Link
                    to="/policies/refund-policy"
                    style={{ color: "#143469", fontSize: "0.8rem" }}
                  >
                    Refund Policy
                  </Link>{" "}
                  |
                  <Link
                    to="/policies/shipping-policy"
                    style={{ color: "#143469", fontSize: "0.8rem" }}
                  >
                    Shipping Policy
                  </Link>
                </p>
              </Col>
            </Row>
            {/* <ul className="multi-links">
              <li>
                <Link to="/products" className="link">
                  Products
                </Link>
                <ul className="page-list">
                  <li>Computing</li>
                  <li>Networking</li>
                  <li>Phyiscal Infrastructure</li>
                  <li>Software and Applications</li>
                  <li>Physical Security</li>
                </ul>
              </li>
              <li>
                <Link to="/solutions" className="link">
                  Solutions
                </Link>
                <ul className="page-list">
                  <li>Audio and Video</li>
                  <li>Building Management Solutions</li>
                  <li>Cloud Computing</li>
                  <li>Data Center</li>
                  <li>Disaster Recovery</li>
                  <li>Physical Security</li>
                  <li>Unified Communications</li>
                </ul>
              </li>
              <li>
                <Link to="/services" className="link">
                  Services
                </Link>
                <ul className="page-list">
                  <li>Engineering and Professional Services</li>
                  <li>IT Infrastructure Services</li>
                </ul>
              </li>
            </ul> */}
          </div>
        </Col>
        <Col md="12" lg="3" className="px-3 px-lg-0">
          <Row className="pt-3 px-3 justify-content-center justify-content-lg-start text-center text-lg-left">
            <p className="mb-0">
              #23, 1st Avenue, Brgy. Bagong Lipunan ng Crame, Cubao, Quezon
              City, Philippines
            </p>
          </Row>
          <Row
            className="mt-1 d-flex flex-row px-3 justify-content-center justify-content-lg-start"
            style={{
              flexWrap: "nowrap",
            }}
          >
            <p className="mb-0 text-nowrap">+632 8287 4114 </p>{" "}
            <p className="mx-2 mb-0 text-nowrap">|</p>{" "}
            <p className="mb-0 text-nowrap">+632 8254 1594</p>
          </Row>
          <Row
            className="mb-1 d-flex flex-row px-3 justify-content-center justify-content-lg-start"
            style={{
              flexWrap: "nowrap",
            }}
          >
            <p className="mb-0 text-nowrap">+632 7720 1310 </p>{" "}
            <p className="mx-2 mb-0 text-nowrap">|</p>{" "}
            <p className="mb-0 text-nowrap">+632 8726 9129</p>
          </Row>
          <Row className="-mt-5 px-3 justify-content-center justify-content-lg-start">
            <p className="mb-0">inquiry@fastlink.ph | helpdesk@fastlink.ph</p>
          </Row>
          {/* <Row className="-mt-5 px-3 justify-content-center justify-content-lg-start ">
            <p className="mb-1"></p>
          </Row> */}
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
