import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const Logo = () => {
    const data = useStaticQuery(graphql`
        query{
            logo: file(relativePath: { eq: "logos/MAINLogo.png" }){
                childImageSharp{
                    fixed(width:260){
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return <Img fixed={data.logo.childImageSharp.fixed} style={{
        width: "280px"
    }} />
}

export default Logo

