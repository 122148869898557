import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Logo from "./logo"

import directory from "../content/directory.json"

import "../assets/css/navbar.css"

const Navigation = props => {
  const [isOpen, setIsOpen] = useState(false)
  const navRef = useRef(null)
  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {

    document.getElementById("spacer").style.height = document.getElementById("navbar").clientHeight + "px"

    if (typeof window !== 'undefined') {
      let prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        const maxScroll = document.body.clientHeight - window.innerHeight;
        let currentScrollPos = window.pageYOffset;
        if (
          (maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll)
          || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
          || (prevScrollpos <= 0 && currentScrollPos <= 0) || (window.screen.width / window.innerWidth !== 1)
        ) {
          document.getElementById("navbar").style.top = "0";
          // document.getElementById("spacer").style.height = document.getElementById("navbar").getBoundingClientRect().height + "px"

        } else {
          document.getElementById("navbar").style.top = document.getElementById("navbar").clientHeight * -1 + "px"; // adjustable based your need
          // document.getElementById("spacer").style.height = "0px"
        }
        prevScrollpos = currentScrollPos;
      }
    }
  }, []);

  return (
    <>

      <Navbar color="white" ref={navRef} className="fixed-top" light expand="xl" id="navbar" style={{
        zIndex: 10
      }}>
        <NavbarBrand className="text-primary logo-nav" href="/" style={{
          maxWidth: "60vw"
        }}>
          <Logo />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink><Link to="/about">About Us</Link></NavLink>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <Link to="/products"> Products</Link>
                </DropdownToggle>
                <DropdownMenu left style={{
                  maxWidth: "10vw",
                  position: "absolute"
                }}>
                  {directory.products.list.map((product, index) => {
                    return (
                      <UncontrolledDropdown key={index} className="subMenu">
                        <DropdownToggle nav>
                          {product.title}
                        </DropdownToggle>
                        <DropdownMenu direction="right" className="linkMenu" style={{
                          // maxWidth: "10vw",
                          position: "absolute"
                        }}>
                          {product.items.map((item, index) => {
                            let slug
                            if (item.name !== undefined) {
                              slug = "/products/" + product.title.toLowerCase().replace(/ /g, "-") + "/" + item.name.toLowerCase().replace(/ /g, "-")
                            }
                            else {
                              slug = ""
                            }

                            return <DropdownItem key={index}><Link to={slug}> {item.name}</Link></DropdownItem>
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <Link to="/solutions"> Solutions </Link>
                </DropdownToggle>
                <DropdownMenu left style={{
                  position: "absolute"
                }}>
                  {directory.solutions.list.map((product, index) => {
                    let slug
                    if (product.title !== undefined) {
                      slug = "/solutions/" + product.title.toLowerCase().replace(/ /g, "-")
                    }
                    else {
                      slug = ""
                    }

                    return (
                      <DropdownItem key={index}><Link to={slug}> {product.title}</Link></DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <Link to="/services"> Services</Link>
                </DropdownToggle>
                <DropdownMenu left style={{
                  position: "absolute"
                }}>
                  {directory.services.list.map((product, index) => {
                    let slug
                    if (product.title !== undefined) {
                      slug = "/services/" + product.title.toLowerCase().replace(/ /g, "-")
                    }
                    else {
                      slug = ""
                    }

                    return (
                      <DropdownItem key={index}><Link to={slug}> {product.title}</Link></DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>

            <NavItem>
              <div>
                <NavLink><a href="/hp/#">HP Catalogue</a></NavLink>
              </div>
            </NavItem>

            <NavItem>
              <NavLink><Link to="/contact">Contact Us</Link></NavLink>
            </NavItem>
          </Nav>
        </Collapse>

      </Navbar>

      <div id="spacer">

      </div>
    </>
  )
}

export default Navigation
