import React from "react"
// import PropTypes from "prop-types"
import Navigation from "./navbar"
import Footer from "./footer"
import "bootstrap/dist/css/bootstrap.min.css"
import "../assets/css/global.css"
import "../assets/css/about.css"
import "../assets/css/products.css"


const Layout = ({ children }) => {
  return (
    <>
      <Navigation />
      {children}
      <Footer />

      {/* <!-- The core Firebase JS SDK is always required and must be listed first --> */}
      {/* <script src="/__/firebase/7.19.0/firebase-app.js"></script> */}

      {/* <!-- TODO: Add SDKs for Firebase products that you want to use
          https://firebase.google.com/docs/web/setup#available-libraries --> */}
      {/* <script src="/__/firebase/7.19.0/firebase-analytics.js"></script> */}

      {/* <!-- Initialize Firebase --> */}
      {/* <script src="/__/firebase/init.js"></script> */}

      {/* Trust SSL Logo Script */}
      <script type="text/javascript" dangerouslySetInnerHTML={{
        __html: ` 
                //<![CDATA[
                  var tlJsHost = ((window.location.protocol == "https:") ? "https://secure.trust-provider.com/" : "http://www.trustlogo.com/");
                  document.write(unescape("%3Cscript src='" + tlJsHost + "trustlogo/javascript/trustlogo.js' type='text/javascript'%3E%3C/script%3E"));
                //]]>`}} />
      <script language="JavaScript" type="text/javascript" dangerouslySetInnerHTML={{
        __html: `TrustLogo("https://www.positivessl.com/images/seals/positivessl_trust_seal_sm_124x32.png", "POSDV", "none"); `
      }} />
    </>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
